import * as React from 'react';
import {
  Layout,
  SEO,
  LWrap,
  CBtnList,
  CUniqueTitle,
  LPlan,
} from '../../../components/_index';

// 
const NotFoundPage = () => {
  let title = "404 not found - [Official] Yokohama Royal Park Hotel";
  let description="Yokohama Royal Park Hotel official site."
  return (
    <Layout checkjump="404" checken="en">
      <SEO checken="en" title={title} description={description} />
      <section className="u_pt120 u_pb120 u_bgAccent">
        <LWrap>
          <CUniqueTitle title={{ main: '404', sub: 'NOT FOUND' }} />
          <h2 className="c_headingLv2 u_mb15">
          The page you are looking for cannot be found
          </h2>
          <p className="c_sectLead">
          The page you are looking for may be temporarily inaccessible, moved or deleted.
            <br />
            We apologize for the inconvenience, but please search for the desired page on the top page.
          </p>
          <CBtnList
            exClass="u_mt40"
            data={[
              {
                label: 'Back to TOP',
                link: {
                  href: '/en/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
